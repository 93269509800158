import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useModal } from '../hooks/modal';
import { useGlobalState, useNexusState } from '../hooks/states';
import { IAlertShowOption } from '../types/AlertTypes';
import { NexusPosition, Position, QueueType } from '../types/lol/partner.type';
import { Tier } from '../types/lol/summoner.type';
import PartnerRegisterModal from './PartnerRegisterModal';
import RegionSelect from './RegionSelect';
import RegisterButton from './RegisterButton';
import TierSelect from './TierSelect';
import { FormattedMessage } from 'react-intl';
import CertifiedToggle from './CertifiedToggle';
import NoRsoModal from './modal/common/NoRsoModal';
import { useCertifiedSummoners } from '../queries/summoners';
import PositionSelect from './PositionSelect';
import NexusPositionSelect from './NexusPositionSelect';
import QueueTypeSelect from './QueueTypeSelect';
import { useRouter } from 'next/router';

interface Props {
  className?: string;
  showAlert: (option: IAlertShowOption) => void;
}

const Filter: React.FC<Props> = ({ className, showAlert }) => {
  const [{ tier, queueType, position, isAuth: certified }, setGlobalState] =
    useGlobalState();
  const [{ tier: nexusTier, position: nexusPosition, isAuth }, setNexusState] =
    useNexusState();
  const { summoners: certifiedSummoners, loading: certifiedSummonersLoading } =
    useCertifiedSummoners();
  const { visible, open: handleOpen, close: closeModal } = useModal();
  const {
    visible: noRsoModalVisible,
    open: handleNoRsoModalOpen,
    close: closeNoRsoModal,
  } = useModal();
  const { pathname } = useRouter();
  const isNexus = useMemo(
    () => pathname.startsWith('/nexus-blitz'),
    [pathname]
  );

  const [showTooltip, setShowTooltip] = React.useState(true);
  const [tooltipTextName, setTooltipTextName] = React.useState('');
  const openModal = () => {
    handleOpen();
    setShowTooltip(false);
    setTooltipTextName('');
    localStorage.setItem('register.summoner.tooltip', 'true');
  };

  const handleRegisterButtonClick = () => {
    if (!certifiedSummoners || certifiedSummoners.length === 0) {
      handleNoRsoModalOpen();
    } else {
      openModal();
    }
  };

  React.useEffect(() => {
    if (localStorage.getItem('register.summoner.tooltip') === 'true') {
      setShowTooltip(false);
      setTooltipTextName('');
      return;
    }
    const random1to100 = Math.floor(Math.random() * 100) + 1;
    if (random1to100 < 33) {
      setTooltipTextName('Register Summoner Tooltip 1');
    } else if (random1to100 < 66) {
      setTooltipTextName('Register Summoner Tooltip 2');
    } else {
      setTooltipTextName('Register Summoner Tooltip 3');
    }
    setTimeout(() => {
      setShowTooltip(false);
      setTooltipTextName('');
    }, 5 * 1000);
  }, [showTooltip]);

  return (
    <div
      className={classNames(
        'flex justify-between items-start lg:items-center',
        className
      )}
    >
      <div className="flex i max-w-full min-w-0 tems-center sm:flex-col sm:items-start">
        {!isNexus ? (
          <PositionSelect
            className="lg:mr-2"
            position={position}
            onChange={handlePosition}
          />
        ) : (
          <NexusPositionSelect
            className="lg:mr-2"
            position={nexusPosition}
            onChange={handleNexusPosition}
          />
        )}
        <div className="flex sm:mt-2 gap-2 max-w-full min-w-0">
          {!isNexus && (
            <QueueTypeSelect
              queueTypes={Object.values(QueueType).filter(
                (queueType) =>
                  queueType !== QueueType.ARAM &&
                  queueType !== QueueType.NEXUS_BLITZ
              )}
              selected={queueType}
              onChange={handleQueueType}
            />
          )}
          <TierSelect
            tiers={Object.values(Tier)}
            selected={isNexus ? nexusTier : tier}
            onChange={isNexus ? handleNexusTier : handleTier}
          />
          <CertifiedToggle
            selected={isNexus ? isAuth : certified}
            onChange={isNexus ? handleNexusCertified : handleCertified}
          />
        </div>
      </div>

      <div className="flex max-w-full min-w-0 lg:space-x-2 sm:flex-col-reverse sm:items-end relative">
        <RegionSelect className="sm:mt-2" />
        <div
          className="flex"
          style={{
            justifyContent: 'center',
            position: 'relative',
          }}
        >
          {!isNexus && (
            <RegisterButton
              name="Register Summoner"
              onClick={handleRegisterButtonClick}
              disabled={certifiedSummonersLoading}
            />
          )}
          {showTooltip && tooltipTextName && (
            <>
              <div
                className="bg-green-900 absolute"
                style={{
                  width: '13px',
                  height: '8px',
                  clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
                  bottom: '-15px',
                }}
              ></div>
              <div
                className="absolute"
                style={{
                  zIndex: 1,
                  right: 0,
                  minWidth: '100%',
                  top: 'calc(100% + 14px)',
                }}
              >
                <div className="relative whitespace-nowrap">
                  <div
                    className="flex"
                    style={{
                      justifyContent: 'center',
                      position: 'relative',
                    }}
                  >
                    <div className="bg-green-900 py-[8px] px-[12px] rounded">
                      <p className="text-sm">
                        <FormattedMessage
                          id={tooltipTextName}
                          values={{ br: <br /> }}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {!visible && (
        <NoRsoModal
          handleOpen={openModal}
          visible={noRsoModalVisible}
          onClose={closeNoRsoModal}
        />
      )}
      <PartnerRegisterModal
        visible={visible}
        onClose={closeModal}
        showAlert={showAlert}
      />
    </div>
  );

  function handlePosition(position: Position): void {
    setGlobalState({ position });
  }

  function handleNexusPosition(position: Position): void {
    setNexusState({ position: position as unknown as NexusPosition });
  }

  function handleQueueType(queueType: QueueType): void {
    setGlobalState({ queueType });
  }

  function handleTier(tier: Tier): void {
    setGlobalState({ tier });
  }

  function handleNexusTier(tier: Tier): void {
    setNexusState({ tier });
  }

  function handleCertified(certified: boolean): void {
    setGlobalState({ isAuth: certified });
  }

  function handleNexusCertified(certified: boolean): void {
    setNexusState({ isAuth: certified });
  }
};

export default Filter;
