import { Stack } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { head, takeWhile } from 'lodash-es';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  formatFromNow,
  formatFullTime,
  replaceTimeUnits,
} from '../formatters/date';
import { usePartnerOptions } from '../hooks/partners';
import { useForceUpdate } from '../hooks/utils';
import { useThemeColors } from '../lib/theme';
import { Locale } from '../types/LocaleTypes';
import { GameResult, PartnerOption } from '../types/PartnerTypes';
import { LoLPartner, Position } from '../types/lol/partner.type';
import { ISummoner } from '../types/lol/summoner.type';
import { getRegion } from '../utils/application';
import { noop, resolveOpTagUrl, resolveOpUrl } from '../utils/misc';
import ChampionIcon from './ChampionIcon';
import EtcPopover from './EtcPopover';
import PartnerBadge from './PartnerBadge';
import PositionIcon from './PositionIcon';
import SummonerTier from './SummonerTier';
import Tooltip from './Tooltip';
import { championDataList } from '../data/championData';
import { getSummonerName } from '../utils/tag';
import PartnerListItemProfile from './SummonerProfile/PartnerListItemProfile';
import MotionPartnerListItemWrapper from './MotionPartnerListItemWrapper';
import { getACECount, getMVPCount, getStreakCount } from '../lib/games';

const A_MINUTE = 1000 * 60;

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  partner: LoLPartner;
  onDelete: () => void;
  onEdit: () => void;
  onReport: () => void;
  onOpenDetail: () => void;
  animated?: boolean;
  setAnimated?: (animated: boolean) => void;
};

const PartnerListItem: React.FC<Props> = ({
  className,
  partner,
  onDelete: handleDelete,
  onEdit: handleEdit,
  onReport: handleReport,
  onOpenDetail: handleDetail,
  animated = true,
  setAnimated = noop,
}: Props) => {
  const {
    id,
    note,
    insertedAt,
    position,
    requiredPosition,
    summoner,
    isAuthor,
  } = partner;
  const { locale = Locale.KO } = useRouter();
  const forceUpdate = useForceUpdate();
  const { hasOption } = usePartnerOptions(partner);
  const { getWinRateClassName } = useThemeColors();

  const winRate = React.useMemo(() => getWinrate(summoner), [summoner]);

  const { type, count } = React.useMemo(
    () => getStreakCount(summoner.gameResults),
    [summoner.gameResults]
  );
  const mvpCount = React.useMemo(
    () => getMVPCount(summoner.gameResults),
    [summoner.gameResults]
  );
  const aceCount = React.useMemo(
    () => getACECount(summoner.gameResults),
    [summoner.gameResults]
  );

  const straightWinsBadge = React.useMemo(
    () =>
      type === 'WIN' &&
      count > 2 && (
        <PartnerBadge className="bg-green-500 text-gray-900">
          <FormattedMessage
            id="{games} Straight Wins"
            values={{ games: count }}
          />
        </PartnerBadge>
      ),
    [type, count]
  );

  const mvpBadge = React.useMemo(
    () =>
      mvpCount > 2 && (
        <PartnerBadge className="bg-yellow-400 text-gray-900">
          {mvpCount} MVP
        </PartnerBadge>
      ),
    [mvpCount]
  );

  const aceBadge = React.useMemo(
    () =>
      aceCount > 2 && (
        <PartnerBadge className="bg-purple-400 text-gray-900">
          {aceCount} ACE
        </PartnerBadge>
      ),
    [aceCount]
  );

  const options = useMemo(() => {
    return {
      premium: partner?.isSubscription,
      certified: hasOption(PartnerOption.CERTIFIED),
      mic: hasOption(PartnerOption.MIC),
    };
  }, [hasOption]);

  const editable = React.useMemo(
    () => (options?.certified ? isAuthor : true),
    [options?.certified, isAuthor]
  );

  const timeSince = dayjs().diff(dayjs(insertedAt));

  React.useEffect(() => {
    const timer = setInterval(forceUpdate, A_MINUTE);
    return () => clearInterval(timer);
  }, []);

  React.useEffect(() => {
    if (timeSince > A_MINUTE) return;

    const timer = setInterval(forceUpdate, 1000);
    const timeoutTimer = setTimeout(() => clearInterval(timer), A_MINUTE);

    return () => {
      clearInterval(timer);
      clearTimeout(timeoutTimer);
    };
  }, []);

  return (
    <MotionPartnerListItemWrapper
      id={`partner-${id}`}
      className={classNames('flex items-center', className)}
      style={{
        borderBottomLeftRadius: 'inherit',
        borderBottomRightRadius: 'inherit',
      }}
      onClick={onOpenDetail}
      onAnimationComplete={() => setAnimated(true)}
      animated={animated}
      isSubscription={partner?.isSubscription}
    >
      <PartnerListItemProfile
        profileImgSrc={summoner.profileImageUrl}
        summoner={summoner}
        options={options}
      />

      <div
        id={`partner-${id}-edit-position`}
        className="w-[66px] py-2 inline-flex justify-center"
      >
        <PositionIcon size="large" position={position ?? Position.ALL} />
      </div>

      <div className="w-[66px] justify-center">
        <SummonerTier
          className="justify-center"
          tier={summoner.tier}
          tierShortened={true}
        />
      </div>

      <div
        id={`partner-${id}-edit-position`}
        className="w-[82px] py-2 inline-flex gap-1 justify-center"
      >
        {requiredPosition && [
          requiredPosition
            .slice(0, 2)
            .map((position) => (
              <PositionIcon
                size="large"
                color="looking-for"
                key={position}
                position={position ?? Position.ALL}
              />
            )),
          requiredPosition.length >= 3 && (
            <span className="text-gray-400">+</span>
          ),
        ]}
      </div>

      <div className="w-36 text-center py-2 px-2.5">
        {summoner.champions.slice(0, 3).map((champion) => (
          <div
            key={`most-champion-${partner.id}-${champion.id}`}
            className="relative inline-block cursor-pointer mr-2 last:mr-0 group"
          >
            <div className="relative inline-block overflow-hidden rounded w-8 h-8 align-top">
              <ChampionIcon
                championId={champion.id}
                championName={
                  championDataList?.[champion.id]?.name[locale as Locale]
                }
              />
              {champion.wr && (
                <span
                  className={classNames(
                    'text-3xs absolute right-0 bottom-0 bg-gray-900 bg-opacity-80 rounded-sm py-0.25 px-0.5',
                    getWinRateClassName(
                      Number(champion.wr.replace(/%/, '')),
                      true
                    )
                  )}
                >
                  {champion.wr}
                </span>
              )}
            </div>

            <div className="opacity-0 group-hover:opacity-100 absolute top-10 left-full -translate-x-1/2 -ml-4 whitespace-nowrap px-2.5 py-2 bg-gray-800 border border-gray-600 rounded z-50 pointer-events-none text-2xs text-left transition-opacity">
              {championDataList?.[champion.id]?.name[locale as Locale]}
              <ul className="mt-2.5">
                <li className="mb-1.5">
                  <span className="inline-block text-gray-400 min-w-[32px] mr-1.5">
                    <FormattedMessage id="Win Rate" />
                  </span>
                  {champion.wr} ({champion.games}{' '}
                  <FormattedMessage id="Games" />)
                </li>
                <li className="mb-1.5">
                  <span className="inline-block text-gray-400 min-w-[32px] mr-1.5">
                    <FormattedMessage id="KDA" />
                  </span>
                  {champion.kda} (
                  <strong className="font-normal">{champion.kdaAvg}</strong>)
                </li>
                <li>
                  <span className="inline-block text-gray-400 min-w-[32px] mr-1.5">
                    CS
                  </span>
                  {champion.cs}
                </li>
              </ul>
            </div>
          </div>
        ))}
      </div>

      <div className="w-40 text-center py-2 px-2.5">
        <div className="flex items-center space-x-2 text-2xs">
          <div
            className={classNames('w-28 h-5 rounded overflow-hidden relative', {
              'bg-gray-700': !Boolean(winRate),
              'bg-red-500': Boolean(winRate),
            })}
          >
            <div
              className={classNames('h-full', {
                'bg-main-500': Boolean(winRate),
                'bg-gray-600': !Boolean(winRate),
              })}
              style={{
                width: winRate > 0 ? winRate + '%' : '50%',
              }}
            />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="absolute inset-0 px-1 text-3xs text-white"
            >
              <span>
                {summoner.wins}
                <FormattedMessage id="W" />
              </span>
              <span>
                {summoner.losses}
                <FormattedMessage id="L" />
              </span>
            </Stack>
          </div>
          <span className={getWinRateClassName(winRate)}>{`${winRate}%`}</span>
        </div>
        {(straightWinsBadge || mvpBadge || aceBadge) && (
          <div className="text-left leading-none mt-1 flex space-x-[2px]">
            {straightWinsBadge}
            {mvpBadge}
            {aceBadge}
          </div>
        )}
      </div>

      <div className="w-24 text-3xs text-center py-2 px-2.5">
        {summoner.kda && (
          <div className="whitespace-nowrap">
            {summoner.kda.replace(/\//g, ' / ')}
          </div>
        )}
        <div
          className={`leading-4 font-bold text-xs ${getKDAClass(
            summoner.kdaAverage
          )}`}
        >
          {summoner.kdaAverage}
        </div>
      </div>

      <div className="flex-1 relative text-center py-2 px-1">
        {note && note !== '' && (
          <Tooltip title={note}>
            <div
              id={`partner-${id}-edit-note`}
              className="bg-gray-700 border border-solid border-gray-600 rounded px-2 py-1 inline-block break-all text-2xs tracking-tight text-left align-top"
            >
              <span className="line-clamp-2">{note}</span>
            </div>
          </Tooltip>
        )}
      </div>

      <div
        className="w-24 flex items-center relative text-3xs text-center py-2 px-2.5"
        title={formatFullTime(insertedAt)}
      >
        <div className="w-20">
          {timeSince > A_MINUTE ? (
            <p>
              {locale !== Locale.EN
                ? formatFromNow(insertedAt, locale)
                : replaceTimeUnits(formatFromNow(insertedAt, locale))}
            </p>
          ) : (
            <p>
              {timeSince > 1000 ? (
                <FormattedMessage
                  id="{d}s ago"
                  values={{ d: Math.floor(timeSince / 1000) }}
                />
              ) : (
                <FormattedMessage id="Now" />
              )}
            </p>
          )}
          {partner.ipAddress && (
            <p className="text-gray-500">{partner.ipAddress}</p>
          )}
        </div>
      </div>

      <EtcPopover
        className="w-8"
        onDelete={editable ? handleDelete : undefined}
        onEdit={editable ? handleEdit : undefined}
        onReport={isAuthor ? undefined : handleReport}
      />
    </MotionPartnerListItemWrapper>
  );

  function getWinrate(summoner: ISummoner): number {
    if (!summoner.wins && !summoner.losses) return 0;

    return Math.round(
      (summoner.wins / (summoner.wins + summoner.losses)) * 100
    );
  }

  function onOpenDetail(event: React.MouseEvent<HTMLElement>): void {
    event.stopPropagation();

    handleDetail();
  }

  function getKDAClass(kdaAvg: number): string {
    if (kdaAvg >= 5) return 'text-yellow-500';
    if (kdaAvg >= 4) return 'text-blue-500';
    if (kdaAvg >= 3) return 'text-green-500';
    return 'text-gray-300';
  }

  function handleClickSummonerName(evt: React.MouseEvent) {
    evt.stopPropagation();
  }
};

export default PartnerListItem;
