import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import { useIntl } from 'react-intl';
import useDeviceDetect from '../hooks/mobile';
import { Optional } from '../types/UtilTypes';
import { SupportedGame } from '../types/game.type';
import { Region } from '../types/lol/summoner.type';
import {
  getRegionConfig,
  getRegion as getRegionFromCookie,
  setRegionConfig,
  setRegion as setRegionToCookie,
} from '../utils/application';
import Tooltip from './Tooltip';
import { dataLayer, GTMEventInfoType, GTMEventType } from '../lib/analytics';
import { REGION_FULL_NAME } from '../utils/constants';

interface Props {
  className?: string;
}

const RegionSelect: React.FC<Props> = ({ className }) => {
  const [region, setRegion] = React.useState<Optional<Region>>();
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const intl = useIntl();
  const theme = useTheme();
  const { isMobile } = useDeviceDetect();
  const { basePath, push: routerPush, query } = useRouter();

  const handleChange = React.useCallback(
    (e: SelectChangeEvent) => {
      onChangeRegion(e.target.value as Region);
    },
    [onChangeRegion]
  );

  const handleClick = React.useCallback(() => {
    setOpenTooltip(false);

    const config = getRegionConfig();
    if (config && config.LOL?.detected === true) {
      setRegionConfig({
        game: SupportedGame.LeagueOfLegends,
        region: config.LOL.region,
        prevConfig: config,
      });
    }
  }, []);

  React.useEffect(() => {
    if (region && region === query.region) return;
    const regionByQuery = getRegionFromQuery();

    if (regionByQuery !== undefined) {
      onChangeRegion(regionByQuery);
      return;
    }
    setRegion(getRegionFromCookie());
  }, [query.region]);

  React.useEffect(() => {
    const config = getRegionConfig();
    if (!config || !config.LOL || !config.LOL.detected) return;

    setOpenTooltip(true);
  }, []);

  if (!region) return null;

  return (
    <div className={classNames('', className)}>
      <Tooltip
        title={intl.formatMessage({ id: 'Is this your region?' })}
        placement="left"
        open={openTooltip}
        componentsProps={{
          popper: {
            sx: {
              zIndex: 10,
              '& .MuiTooltip-tooltip': {
                borderColor: theme.palette.primary.main,
              },
              '& .MuiTooltip-arrow': {
                color: `${theme.palette.primary.main}!important`,
              },
            },
          },
        }}
      >
        <FormControl
          fullWidth
          size="small"
          sx={(theme) => ({ flex: 1, minHeight: theme.spacing(5.25) })}
        >
          <Select
            value={region}
            onMouseOver={handleClick}
            onClick={handleClick}
            onChange={handleChange}
            displayEmpty
            sx={{
              flex: 1,
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'text.disabled',
                borderWidth: '1px !important',
              },
              '& .MuiOutlinedInput-notchedOutline:hover': {
                borderColor: 'text.secondary',
              },
            }}
            className="bg-gray-800"
          >
            {Object.values(Region).map((region: Region) => (
              <MenuItem key={`lol-region-${region}`} value={region}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <img
                    src={`https://s-lol-web.op.gg/assets/images/regions/01-icon-icon-${region}.svg?v=1673930547899`
                      .replace('sg', 'si')
                      .replace('tw', 'ta')
                      .replace('vn', 'vi')}
                    alt={region.toUpperCase()}
                    width={16}
                  />
                  <Typography className="truncate">
                    {REGION_FULL_NAME[region.toUpperCase()] ||
                      region.toUpperCase()}
                  </Typography>
                </Stack>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Tooltip>
    </div>
  );

  function getRegionFromQuery() {
    const region = query.region as Region;

    if (!region || !Object.values(Region).includes(region)) return;
    return region;
  }

  function onChangeRegion(region: Region): void {
    setRegion(region);
    setRegionToCookie(region);
    setRegionConfig({
      game: SupportedGame.LeagueOfLegends,
      region,
      prevConfig: getRegionConfig(),
    });
    dataLayer(
      {
        op_event: GTMEventInfoType.CLICK_REGION_FILTER,
        op_event_value: region,
      },
      GTMEventType.CLICK
    );

    routerPush(
      {
        pathname: basePath,
        query: {
          region,
        },
      },
      undefined,
      {
        shallow: true,
      }
    );
  }
};

export default RegionSelect;
