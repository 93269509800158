import classNames from 'classnames';
import React from 'react';
import TableHeaderCell from './TableHeaderCell';

type Props = {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
};

const PartnerListHeader: React.FC<Props> = ({ className }) => {
  return (
    <div className={classNames(className, 'flex bg-gray-850 rounded-t')}>
      <TableHeaderCell id="Summoner Name" className="w-44 rounded-tl" />
      <TableHeaderCell id="Position" className="w-[66px]" />
      <TableHeaderCell id="Tier" className="w-[66px]" />
      <TableHeaderCell id="Looking for" className="w-[82px]" />
      <TableHeaderCell id="Latest Champions" className="w-36" />
      <TableHeaderCell id="Win Rate" className="w-40" />
      <TableHeaderCell id="KDA" className="w-24" />
      <TableHeaderCell id="Note" className="flex-1" />
      <TableHeaderCell id="Registered" className="w-24" />
      <div className="w-8" />
    </div>
  );
};

export default PartnerListHeader;
